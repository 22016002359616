import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeSphinxStreamingRequest } from 'services/api/sphinx'

// Sphinx is our interal AI Service https://github.com/Junglescout/sphinx

export const useStreamingRequest = () => {
  const { t } = useTranslation('chatbot')

  const [streamingString, setStreamingString] = useState()
  const [isStreaming, setStreaming] = useState(false)
  const [error, setError] = useState()

  const setErrorMessage = e =>
    setError(
      e?.message ||
        t(
          'chatbot:Errors.genericRequestError',
          "I can't process your request at the moment. Please try again."
        )
    )

  const sendStreamingRequest = async ops => {
    setStreaming(true)
    setError()
    setStreamingString()

    await makeSphinxStreamingRequest(ops, callback => {
      if (callback.error) {
        setErrorMessage(callback.error)
      } else if (callback.text) {
        setStreamingString(callback.text)
      }
    })

    setStreaming(false)
  }

  return {
    streamingString,
    sendStreamingRequest,
    isStreaming,
    error
  }
}
